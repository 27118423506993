/* Код для подключения шрифта в /css/stylename.css */

@font-face {
	font-family: "GothamProRegular";
	src: url("./fonts/GothamProRegular/GothamProRegular.eot");
	src: url("./fonts/GothamProRegular/GothamProRegular.eot?#iefix") format("embedded-opentype"),
		url("./fonts/GothamProRegular/GothamProRegular.woff") format("woff"),
		url("./fonts/GothamProRegular/GothamProRegular.ttf") format("truetype");
	font-style: normal;
	font-weight: normal;
}

@font-face {
	font-family: "GothamProBold";
	src: url("./fonts/GothamProBold/GothamProBold.eot");
	src: url("./fonts/GothamProBold/GothamProBold.eot?#iefix") format("embedded-opentype"),
		url("./fonts/GothamProBold/GothamProBold.woff") format("woff"),
		url("./fonts/GothamProBold/GothamProBold.ttf") format("truetype");
	font-style: normal;
	font-weight: normal;
}

@font-face {
	font-family: "GothamProBlack";
	src: url("./fonts/GothamProBlack/GothamProBlack.eot");
	src: url("./fonts/GothamProBlack/GothamProBlack.eot?#iefix") format("embedded-opentype"),
		url("./fonts/GothamProBlack/GothamProBlack.woff") format("woff"),
		url("./fonts/GothamProBlack/GothamProBlack.ttf") format("truetype");
	font-style: normal;
	font-weight: normal;
}

@font-face {
	font-family: "GothamProItalic";
	src: url("./fonts/GothamProItalic/GothamProItalic.eot");
	src: url("./fonts/GothamProItalic/GothamProItalic.eot?#iefix") format("embedded-opentype"),
		url("./fonts/GothamProItalic/GothamProItalic.woff") format("woff"),
		url("./fonts/GothamProItalic/GothamProItalic.ttf") format("truetype");
	font-style: normal;
	font-weight: normal;
}

@font-face {
	font-family: "GothamProMedium";
	src: url("/fonts/GothamProMedium/GothamProMedium.eot");
	src: url("/fonts/GothamProMedium/GothamProMedium.eot?#iefix") format("embedded-opentype"),
		url("/fonts/GothamProMedium/GothamProMedium.woff") format("woff"),
		url("/fonts/GothamProMedium/GothamProMedium.ttf") format("truetype");
	font-style: normal;
	font-weight: normal;
}

@font-face {
	font-family: "GothamProLight";
	src: url("./fonts/GothamProLight/GothamProLight.eot");
	src: url("./fonts/GothamProLight/GothamProLight.eot?#iefix") format("embedded-opentype"),
		url("./fonts/GothamProLight/GothamProLight.woff") format("woff"),
		url("./fonts/GothamProLight/GothamProLight.ttf") format("truetype");
	font-style: normal;
	font-weight: normal;
}

@font-face {
	font-family: "GothamProMediumItalic";
	src: url("./fonts/GothamProMediumItalic/GothamProMediumItalic.eot");
	src: url("./fonts/GothamProMediumItalic/GothamProMediumItalic.eot?#iefix") format("embedded-opentype"),
		url("./fonts/GothamProMediumItalic/GothamProMediumItalic.woff") format("woff"),
		url("./fonts/GothamProMediumItalic/GothamProMediumItalic.ttf") format("truetype");
	font-style: normal;
	font-weight: normal;
}

@font-face {
	font-family: "GothamProLightItalic";
	src: url("./fonts/GothamProLightItalic/GothamProLightItalic.eot");
	src: url("./fonts/GothamProLightItalic/GothamProLightItalic.eot?#iefix") format("embedded-opentype"),
		url("./fonts/GothamProLightItalic/GothamProLightItalic.woff") format("woff"),
		url("./fonts/GothamProLightItalic/GothamProLightItalic.ttf") format("truetype");
	font-style: normal;
	font-weight: normal;
}

@font-face {
	font-family: "GothamProBoldItalic";
	src: url("./fonts/GothamProBoldItalic/GothamProBoldItalic.eot");
	src: url("./fonts/GothamProBoldItalic/GothamProBoldItalic.eot?#iefix") format("embedded-opentype"),
		url("./fonts/GothamProBoldItalic/GothamProBoldItalic.woff") format("woff"),
		url("./fonts/GothamProBoldItalic/GothamProBoldItalic.ttf") format("truetype");
	font-style: normal;
	font-weight: normal;
}

@font-face {
	font-family: "GothamProBlackItalic";
	src: url("./fonts/GothamProBlackItalic/GothamProBlackItalic.eot");
	src: url("./fonts/GothamProBlackItalic/GothamProBlackItalic.eot?#iefix") format("embedded-opentype"),
		url("./fonts/GothamProBlackItalic/GothamProBlackItalic.woff") format("woff"),
		url("./fonts/GothamProBlackItalic/GothamProBlackItalic.ttf") format("truetype");
	font-style: normal;
	font-weight: normal;
}

body {
	margin: 0;
	font-family: "GothamProRegular", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
		"Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow-x: hidden;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

ul {
	padding: 0;
	margin: 0;
}

li {
	list-style: none;
}

.main-container {
	position: relative;
	width: 100%;
	max-width: 1225px;
	margin: 0 auto;
	/* overflow-x: hidden; */
}

img.img-responsive {
	width: 100px;
	height: 100px;
}

.wrap-main_image .main-image {
	position: absolute;
	z-index: 1;
	top: 0;
	width: 100%;
	height: auto;
	animation-name: appear;
	animation-duration: 1s;
}

@keyframes appear {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

.main-background {
	width: 100%;
	height: auto;
}

.common-list-options {
	position: absolute;
	top: 0;
	z-index: 2;
	padding-right: 5%;
	padding-top: 7%;
	padding-left: 5%;
	bottom: 5px;
	left: 0;
	right: auto;
	overflow-y: auto;
	background: rgba(0, 0, 0, 0.6);
}

.common-list-options li {
	position: relative;
	margin-bottom: 30px;
	width: 225px;
	background: rgba(255, 255, 255, 0.4);
	height: 150px;
	cursor: pointer;
    border: 1px solid #ffffff;
}

.common-list-options li:hover {
	box-shadow: 0 0 10px rgba(0,0,0,0.5); 
}

.common-list-options li.active-item_list {
	background: #fff;
}


.common-list-options li img {
	position: absolute;
	width: 100%;
	height: 100%;
	object-fit: cover;
	transition: 0.3s all ease-in-out;
}

.main-roof {
	position: absolute;
	top: 0;
	z-index: 1;
	width: 100%;
	height: auto;
}

.main-title {
	color: #333f48;
	font-size: 14px;
	line-height: 1.26;
}

.main-list_nav {
	position: relative;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-right: 25px;
}

.main-list_nav li {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 150px;
	margin-right: 30px;
	overflow: hidden;
}

.main-list_nav li:hover {
	box-shadow: 0 0 10px rgba(0,0,0,0.5); 
}

.main-list_nav li:first-child .list-item_btn > img {
	width: 100%;
	height: 100%;
	top: 10px;
}

.main-list_nav li:last-child {
	margin-right: 0;
}

.main-label {
    text-align: center;
    font-size: 14px;
    display: flex;
    color: #333f48;
    align-items: center;
    justify-content: center;
    min-height: 40px;
    background: #ffffffd1;
    border-left-width: 5px;
    position: absolute;
    bottom: 0;
    width: 100%;
    font-weight: 700;
}

.list-item_btn {
    position: relative;
    height: 100%;
    cursor: pointer;
    outline: none;
    background: transparent;
    border: 0;
    padding: 0;
}

.list-item_btn img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	transition: 0.3s all ease-in-out;
}


.list-item_btn .fa-angle-up {
	position: absolute;
	font-size: 19px;
	top: 0;
	right: 5px;
}

.list-item_btn .main-icon {
	font-size: 60px;
	transition: 0.3s all ease-in-out;
}

.main-list_navigation {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	flex-wrap: wrap;
	width: 100%;
	/* max-width: 1250px; */
	margin: 0 auto;
	/* height: 265px; */
	/* padding: 0 5px; */
}

.main-list_navigation > li:first-child {
	position: relative;
	width: 100%;
	max-width: 520px;
}

.main-list_navigation > li:nth-child(2) {
	position: relative;
	width: 100%;
	max-width: 200px;
}

.main-list_navigation > li:nth-child(2):hover .paving-area {
	display: flex;
}


.main-list_navigation > li:last-child:hover .counting-result-tooltip {
	display: flex;
}

.main-list_navigation > li:nth-child(3) {
	width: 100%;
	max-width: 172px;
	margin-right: 20px;
}

.main-logo {
	display: block;
	padding-top: 15px;
}

.main-logo img {
	width: 115%;
}

.children-attributes {
	display: flex;
	position: absolute;
	top: 0;
	z-index: 1;
	width: calc(100% - 369px);
	padding-top: 7%;
	height: 180px;
	right: 0;
	overflow-x: auto;
	overflow-y: hidden;
	/* background: rgba(0, 0, 0, 0.6) */
}

.children-attributes li {
	position: relative;
	display: inline-block;
	width: 195px;
	height: 150px;
	cursor: pointer;
	background: rgba(255, 255, 255, 0.4);
	margin-right: 10px;
	border: 1px solid #ffffff;
}

.children-attributes li:hover  {
	box-shadow: 0 0 10px rgba(0,0,0,0.5);
}

.children-attributes li > img {
	width: 195px;
	height: 150px;
	object-fit: cover;
	transition: 0.3s all ease-in-out;
}

img.current-attribute {
	position: absolute;
	z-index: 1;
	top: 0;
	width: 100%;
	height: auto;
	transition: 0.3s all ease-in-out;
}

.listCalculate {
	margin-right: 25px;
	/* margin-top: 20px; */
}

/* .listRoofsTop1 {
  padding-top: 10%;
}

.listRoofsTop2 {
  padding-top: 300px;
}

.listRoofsTop3 {
  padding-top: 483px;
} */

.listCalculate li {
	position: relative;
	display: flex;
	align-items: center;
	height: 40px;
	margin-bottom: 15px;
}

.listCalculate li > label {
	position: absolute;
	display: flex;
	font-size: 11px;
	justify-content: center;
	color: #000;
	background: #fff;
	align-items: center;
	text-transform: uppercase;
}

.listCalculate li>input {
    width: 100%;
    height: 40px;
    margin: 0;
    text-align: left;
    padding: 0 0 0 15px;
    border-top: 1px solid #9E9E9E;
    outline: none;
    border-bottom: 1px solid #9e9e9e;
    border-left: 0px;
	border-right: 0px;
	font-family: GothamProRegular,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
}

.listCalculate li > input::-webkit-input-placeholder {
	/* padding-left: 15px; */
	text-transform: none;
	text-align: left;
}

.plaza-area {
	font-weight: bold;
	font-size: 14px;
	color: #333f48;
}

.result-text_area {
	width: 200px;
	height: 150px;
	resize: none;
}

.wrap-result-btns {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-top: 5px;
}

.wrap-result-btns button {
	width: 100%;
	height: 42px;
	margin-bottom: 12px;
	background: #9b2743;
	border: 0;
	color: #fff;
	cursor: pointer;
	font-size: 14px;
	transition: 0.3s all ease-in-out;
}

.na-email {
	background: none;	
}

.wrap-result-btns button:hover {
	background: #92243f;
}

.image-type_of-tile {
	width: 100%;
	height: 106px;
	border: 0px solid #9b2743;
	background-color: #ddd;
}

.talePreview {
	width: 170px;
}

.color-till_caption,
.color-till {
	font-size: 14px;
}

.calculationField {
	text-align: center;
}

.preview-block {
	width: 170px;
}

.form-result {
	margin-right: 45px;
}

.caption-type_of-till {
    display: block;
    padding-top: 6px;
	font-size: 18px;
	color: #333f48;
	font-family: GothamProBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
}

/* .list-tales {
  position: absolute;
  top: 0;
  z-index: 1;
  padding-right: 5%;
  padding-top: 10%;
  padding-left: 4.2%;
  bottom: 10px;
  left: 0;
  right: auto;
  overflow: auto;
  background: rgba(0, 0, 0, 0.6);
}

.list-tales li {
  position: relative;
  margin-bottom: 30px;
  width: 225px;
  background: rgba(255, 255, 255, 0.4);
  height: 150px;
  cursor: pointer;
  border: 1px solid #9b2743;
} */

.list-tales li > span {
	position: absolute;
	z-index: 1;
	top: 15px;
	left: 15px;
	color: #fff;
	font-weight: bold;
	line-height: 22px;
}

.wrap-main_preview {
	position: relative;
}

.wrap-main_preview::before {
	content: "";
	position: absolute;
	bottom: 0;
	z-index: 1;
	width: 100%;
	border-bottom: 5px solid #9b2743;;
}

.example-enter {
	opacity: 0.01;
}

.example-enter.example-enter-active {
	opacity: 1;
	transition: opacity 500ms ease-in;
}

.example-leave {
	opacity: 1;
}

.example-leave.example-leave-active {
	opacity: 0.01;
	transition: opacity 300ms ease-in;
}

.wrap-main_preview ul li.active-item {
	background: #fff;
}

.list-result_calc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 5px;
    padding-left: 10px;
    padding-top: 10px;
    height: 97px;
}

.list-result_calc li {
	padding-bottom: 4px;
	color: #333f48;
}

.list-result_calc li span:first-child {
	font-weight: bold;
}

.result-type_tale {
	padding-top: 10px;
}

.result-type_tale li {
	margin-bottom: 5px;
	color: #333f48;
}

.result-type_tale li span:first-child {
	font-weight: bold;
}

.how-to-start {
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -135px;
    left: 20px;
    width: 100%;
    max-width: 100px;
    background: #243f4f;
    text-align: center;
    border-radius: 10px;
    padding: 10px;
    border: 0px solid #9b2743;
    color: #ffffff;
}

.how-to-start::before {
	content: "";
	position: absolute;
	width: 0;
	height: 0;
	border-left: 15px solid transparent;
	border-right: 15px solid transparent;
	border-top: 15px solid #243f4f;
	font-size: 0;
	line-height: 0;
	bottom: -15px;
	/* margin-top: 41px; */
}

.main-list_nav > li:hover .main-tooltip-facade,
.main-list_nav > li:hover .main-tooltip-tale {
	display: flex;
}

.main-tooltip-facade {
	left: 190px;
	top: -151px;
	z-index: 1;
	display: none;
	transition: 0.3s all ease-in-out;
}

.main-tooltip-facade::before {
	margin-top: 50px;
}

.main-tooltip-tale {
	left: 370px;
	top: -153px;
	z-index: 1;
	display: none;
	transition: 0.3s all ease-in-out;
	border: 0px solid #9b2743;
}

.paving-area {
	display: none;
	top: -95px;
}

.paving-area::before {
	margin-top: 42px;
}

.main-tooltip-tale::before {
	margin-top: 50px;
}

.counting-result-tooltip {
	display: none;
	top: -80px;
}

.counting-result-tooltip::before {
	margin-top: 34px;
}

.preview-tale_color {
	position: absolute;
	/* top: 10px; */
	left: 10px;
	z-index: 1;
	color: #fff;
	font-size: 16px;
}

.color-main_previews {
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 1;
	color: #fff;
	background: rgba(0, 0, 0, 0.5);
	right: 0;
	padding: 5px 10px;
}

.form-popup {
    position: absolute;
    top: 0;
    width: 100%;
    right: 0;
    left: 0;
    z-index: 2;
    /* max-width: 1000px; */
    margin: 0 auto;
    background-color: #191919ad;
    height: 100%;
}

.form-container {
    position: relative;
    width: 80%;
    padding: 20px;
    overflow: hidden;
    background-color: #fff;
    top: 15%;
    margin: auto;
}

.form-container h1 {
	color: #333f48;
	font-family: GothamProBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
}

.form-container input[type="text"] {
	box-sizing: border-box;
	width: 100%;
	height: 40px;
	margin-bottom: 15px;
	padding-left: 15px;
	border: none;
	background: #f1f1f1;
}

.form-container input[type="checkbox"] {
	margin-right: 10px;
}

.form-container input[type="text"]:focus,
.form-container input[type="password"]:focus {
	background-color: #ddd;
	outline: none;
}

@media (max-width: 450px) {
	.form-container h1 {
		margin-left: 20px;
	}
}

.form-container h2 {
	margin: 1%;
	text-align: center;
	color: #333f48;
	font-family: GothamProBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
}

@media (max-width: 450px) {
	.form-container h2 {
		margin: 0;
		text-align: left;
	}
}

.form-container .btn {
	width: 100%;
	padding: 16px 20px;
	font-size: 18px;
	color: white;
	border: none;
	cursor: pointer;
	background-color: #4E9425;
}

.form-container .cancel {
	position: absolute;
	top: 0;
	right: 0;
	width: 50px;
	padding: 0;
	font-size: 20px;
	text-align: center;
	background-color: #9b2743;
}

.form-container .cancel:hover {
	background-color: #92243f;
}

label.confirm-label {
	font-size: 16px;
	color: #787878;
}

.email-report div {
	display: inline-block;
}

div.confirm-block {
	width: 100%;
}

.main-tooltip-roofs {
	left: 40px;
	top: -70px;
	display: none;
}

.main-tooltip-roofs::before {
	margin-top: 34px;
}

.children-attributes li:hover .main-tooltip-roofs {
	display: flex;
}

.tooltip-textures {
	top: -86px;
}

.tooltip-textures::before {
	margin-top: 43px;
}

.form-popup__wrapper {
	display: flex;
}

.form-popup__wrapper .preview-block h2 {
	margin-top: 0;
}

.form-popup__wrapper-input {
	padding-left: 30px;
}

.form-popup__wrapper .form-popup__wrapper-calculation {
	width: 100%;
	max-width: 200px;
	margin-left: 15px;
}

.form-popup__wrapper .form-popup__wrapper-calculation h2 {
	margin-top: 0;
}

@media screen and (max-width: 1250px) {
	.main-list_navigation > li:first-child {
		max-width: 80%;
		margin: 0 auto;
	}

	.main-list_navigation > li:nth-child(2) {
		max-width: 300px;
		margin: 0 auto;
	}

	.listCalculate {
		margin-right: 10px;
	}

	.main-list_navigation > li:last-child {
		margin: 0 auto;
	}

	.main-list_navigation > li:nth-child(3) {
		margin: 0 auto;
	}

	.main-tooltip-facade {
		display: none !important;
	}

	.how-to-start {
		display: none !important;
	}
}

@media screen and (max-width: 1024px) {
	.common-list-options {
		padding-right: 3%;
		padding-top: 3%;
		padding-left: 2%;
		bottom: 0;
	}

	.common-list-options li {
		width: 150px;
		height: 115px;
	}

	.children-attributes {
		width: calc(100% - 206px);
		padding-top: 3%;
		height: 112px;
		left: 200px;
	}

	.children-attributes li {
		width: 200px;
		height: 115px;
	}

	.children-attributes li span {
		font-size: 12px;
	}

	.children-attributes li > img {
		width: 200px;
		height: 115px;
	}

	.list-tales li > span {
		padding: 5px;
		font-size: 12px;
	}

	.main-logo img {
		width: 100%;
	}

	.form-popup__wrapper {
		flex-direction: column;
	}

	.form-popup__wrapper .preview-block {
		width: 200px;
		margin: 0 auto;
	}

	.form-popup__wrapper .form-popup__wrapper-calculation {
		margin: 0 auto;
	}



	.form-popup__wrapper-input {
		padding: 0;
	}

	.form-container {
		padding: 0;
	}
	.form-container, .form-popup__wrapper-input {
		padding: 20px;
		max-width: 500px;
	}
}

@media screen and (max-width: 767px) {
	.common-list-options {
		padding-right: 2%;
	}

	.common-list-options li {
		width: 70px;
		height: 50px;
		margin-bottom: 10px;
	}

	.children-attributes {
		width: calc(100% - 90px);
		height: 90px;
		left: 90px;
	}

	.children-attributes li {
		width: 100px;
		height: 80px;
	}

	.children-attributes li > img {
		width: 95px;
		height: 80px;
	}

	.list-tales li > span {
		padding: 0;
		font-size: 10px;
		line-height: 1;
		top: 2px;
		left: 2px;
	}

	.main-list_navigation > li:first-child {
		max-width: 100%;
		text-align: center;
	}

	.main-list_nav {
		margin: 0 15px;
		margin-top: 25px;
		margin-bottom: 20px;
	}

	.main-list_nav li {
		margin: 0;
		width: 30%;
		height: 150px;
	}

	.main-list_nav .main-tooltip-tale {
		display: none;
	}

	.main-list_nav .how-to-start {
		display: none !important;
	}

	.main-logo {
		margin-left: 15px;
		margin-bottom: 15px;
	}

	.main-logo img {
		width: 100%;
	}
	.main-list_navigation>li:last-child {
		width: 100%;
        max-width: 300px;
        margin: 0 auto;
	} 

	.main-list_navigation > li:nth-child(2) {
		margin: 0 auto;
	}

	.main-list_navigation > li:nth-child(3) {
		width: 100%;
		max-width: 300px;
		margin: 0 auto;
	}

	.image-type_of-tile {
		width: 300px;
		height: 100%;
	}

	.talePreview {
		width: 100%;
		height: 200px;
	}

	.image-type_of-tile {
		width: 100%;
	}

	.preview-block {
		width: 100%;
	}

	.main-list_navigation > li:last-child {
		margin: 0 auto;
		margin-top: 20px;
	}

	.main-label {
		font-size: 10px;
	}

	.color-main_previews {
		font-size: 10px;
	}
	.list-result_calc {
		margin-bottom: 15px;
		padding-left: 0px;
		padding-top: 0px;
	}
}

@media screen and (max-width: 500px) {
	.main-list_nav li {
		height: 100px;
	}
}
